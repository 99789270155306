<!--
 * @Description: 首页预览
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-12 10:32:45
 * @LastEditTime: 2020-07-17 14:44:10
 -->
 <template>
  <div>
    <div class="app-phone" >
      <template v-for="(comp, idx) in compList">
        <div :key="idx">
          <div v-if="comp.type === 'placeholder'" class="split-margin"></div>
          <app-swiper v-if="comp.type === 'app-swiper'" appendId="_pre" :component="comp"></app-swiper>
          <split-image v-if="comp.type === 'split-image'" :component="comp"></split-image>
          <hot-sale v-if="comp.type === 'hotSale' && comp.action.config.isShow" :component="comp"></hot-sale>
          <douyin-hot-sale v-if="comp.type === 'douyinHotSale' && comp.action.config.isShow" :component="comp"></douyin-hot-sale>
          <high-commission v-if="comp.type === 'highCommission' && comp.action.config.isShow" :component="comp"></high-commission>
          <high-discount v-if="comp.type === 'highDiscount' && comp.action.config.isShow" :component="comp"></high-discount>
          <recommend-goods v-if="comp.type === 'recommendGoods'" :component="comp"></recommend-goods>
          <king-kong v-if="comp.type === 'king-kong'" :component="comp"></king-kong>
          <v-goods-bang v-if="comp.type === 'v-goods-bang'" :isInHome="true" :component="comp"></v-goods-bang>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppHomePreview",
  components: {},
  props: {
    compList: {
      default: Object
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.split-margin {
  margin-bottom: 10px;
}
</style>
