<!--
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-03 17:30:54
 * @LastEditTime: 2020-05-26 21:00:27
 * @Description: 意见反馈
 -->
<template>
  <div id="userManager">
    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="'operate_manage_custom_list'"
      :rowKey="record => record.id"
      :columns="feedbackColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ emptyText: '暂无数据' }"
      @change="handleTableChange"
    >
      <template slot="operation" slot-scope="record">
        <a-button size="small" class="mr-10" @click="handleShowPreviewModal(record)">查看</a-button>
        <a-button size="small" class="mr-10" v-auth="'operate_manage_modify_custom'" @click="handleGoAppHomeMaker(record.id)">编辑</a-button>
      </template>
    </a-table>
    <!--查看预览-->
    <HomePreviewDrawer
      :data="tempFeedbackData"
      v-if="feedbackDetailModalVisiable"
      @callback="hideFeedbackModal"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import Components from '@/components/h5maker'
import { getCustomModule } from "@/service/operate";
import HomePreviewDrawer from "@/components/admin/operate/drawer-home-preview";
import { checkPer } from "@/utils/index";
Vue.use(Components)
// 寄样申请列表
const feedbackColumns = [
  {
    title: "模块名称",
    fixed: 'left',
    dataIndex: "module_name",
    align: "left",
    width: 400
  },
  {
    title: "更新人",
    dataIndex: "modified_by__username",
    align: "center",
    width: 160
  },
  {
    title: "最后编辑",
    dataIndex: "update_time",
    align: "center",
    width: 80
  },
  {
    title: "操作",
    align: "center",
    fixed: 'right',
    width: 150,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "FeedbackPage",
  components: {
    HomePreviewDrawer
  },
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      feedbackColumns,
      feedbackDetailModalVisiable: false,
      // 临时账号数据
      tempFeedbackData: {},
      listLoading: true,
      list: [],
      allRoles: [],
      searchParams: {
        search: "",
        is_active: undefined
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 360;
  },
  mounted() {
    this.getHomeConfigList();
  },
  methods: {
    // 跳转到首页配置
    handleGoAppHomeMaker(id) {
      // let routeUrl = this.$router.resolve({
      //   path: "/homeMaker",
      //   query: { id }
      // });
      // window.open(routeUrl.href, "_blank");
      this.$router.push({ path: '/homeMaker', query: { id } })
    },
    // 获取列表
    async getHomeConfigList() {
      if (!checkPer("operate_manage_custom_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      const params = Object.assign(
        { page: this.pagination.current, page_size: this.pagination.pageSize },
        this.searchParams
      );
      this.listLoading = true;
      const { err, res } = await getCustomModule(params);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getHomeConfigList();
    },
    // 显示查看
    handleShowPreviewModal(data) {
      this.tempFeedbackData = JSON.parse(JSON.stringify(data));
      this.feedbackDetailModalVisiable = true;
    },
    // 搜索查询按钮
    accountSearchCallback(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.getHomeConfigList();
    },
    // 隐藏用户信息drawer
    hideAccountModal(isNeedFresh = -1) {
      this.accountModalVisiable = false;
      if (isNeedFresh === 1) {
        this.pagination.current = 1;
        this.getHomeConfigList();
      }
      if (isNeedFresh === 0) {
        this.getHomeConfigList();
      }
    },
    // 隐藏用户详细信息drawer
    hideFeedbackModal() {
      this.feedbackDetailModalVisiable = false;
    }
  }
};
</script>
<style lang='less' scoped>
  #userManager{
    /deep/ .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
  }
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
.mr-10 {
  margin-right: 10px;
}
.app-home-d-block {
  text-align: center;
  padding: 0 15px;
  .default-c-box {
    img {
      display: block;
      width: 100%;
    }
  }
}


  .app-phone {
    position: relative;
    box-sizing: border-box;
    width: 377px;
    min-height: 667px;
    padding-bottom: 200px;
    margin: 0 auto;
    background-color: #F4F6F6;



    .place-holder {
      position: relative;
      display: block;
      height: 10px;
      text-align: center;
      font-size: 0;
      background: #fff;
      background-image: linear-gradient(#fff 2px, transparent 0),
        linear-gradient(90deg, #999 1px, transparent 0);
      background-size: 3px 3px, 3px 3px;

      &.active {
        height: 40px;
        line-height: 40px;
        border: 1px dashed #f32e37;
        background-color: #ffe800;
        background-image: none;
        color: #333;
        font-size: 14px;
        margin: 0;
      }
    }

    .tpl-container {
      position: relative;
      width: 100%;
      background-color: transparent;
      position: relative;
      border: 1px solid #f2f3f4;
      transition: opacity 0.1s ease-out;
      user-select: none;
      box-sizing: border-box;
      z-index: auto;
      opacity: 1;
      cursor: pointer;

      &.current {
        border: 1px solid #2aa7ff;

        .comp-menu {
          display: block;
        }
      }

      .comp-content {
        position: relative;
      }
    }
  }

  .comp-menu {
    position: absolute;
    display: none;
    top: 0;
    right: -38px;
    width: 32px;

    > a {
      display: inline-block;
      position: relative;
      width: 36px;
      height: 36px;
      margin-bottom: 2px;
      text-align: center;
      color: #666;
      background: #fff;
      font-size: 18px;
      line-height: 36px;

      &.disabled {
        color: #e4e4e4;
      }

      &:hover {
        &.disabled {
          color: #e4e4e4;
        }
        color: #333;
        .tips {
          display: block;
        }
      }

      .tips {
        font-size: 12px;
        position: absolute;
        left: 120%;
        background-color: #111;
        height: 18px;
        padding: 0 5px;
        line-height: 19px;
        border-radius: 2px;
        white-space: nowrap;
        display: none;
        color: #eee;
        top: 5px;

        &::before {
          content: " ";
          display: block;
          position: absolute;
          left: -5px;
          top: 4.5px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 6px 5px 0;
          border-color: transparent #111 transparent transparent;
        }
      }
    }
  }

  .absolute-tpl {
    width: 377px;
    margin: 0 auto;
    transform: translateX(-20px);
    border-top: 1px solid #e8e8e8;

    &.current {
      border: 1px solid #2aa7ff;

      .comp-menu {
        display: block;
      }
    }
  }
</style>
