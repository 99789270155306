<!--
 * @Description: 意见反馈
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-05-12 15:32:28
 -->
 <template>
  <div class="account-modal">
    <a-drawer
      title="App首页预览"
      width="760px"
      placement="right"
      @close="handleHideThisModal"
      :visible="homePreviewDrawerVisible"
    >
       <AppHomePreview :compList="compList" />
    </a-drawer>
  </div>
</template>
<script>
import AppHomePreview from "@/components/h5maker/apphome/home-preview.vue";
import { getCustomModule } from "@/service/operate";
export default {
  name: "HomePreviewDrawer",
  components: {
    AppHomePreview
  },
  props: {
    data: {
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      homePreviewDrawerVisible: true,
      options: {
        url: "data-src"
      },
      compList:[]
    };
  },
  methods: {
    // 隐藏modal
    handleHideThisModal(page = -1) {
      this.$emit("callback", page);
    },
    // 获取homePreview数据
    async getHomePreviewData() {
      const _params = {
        id: 1,
        extra_fields: 'backend_settings'
      }
     const {err, res}  = await getCustomModule(_params);
     if(!err){
       if(res.success){
         if(res.data.count > 0){
           this.compList = res.data.results[0]['backend_settings']
         }


       }
     }
    }
  },
  mounted(){
    this.getHomePreviewData()
  }
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.n-table {
  th,
  td {
    font-weight: normal;
    padding: 5px;
  }
  th {
    text-align: right;
  }
}
.pic-box {
  img {
    height: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
</style>
